import { Box, Flex, Text } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { VFC, forwardRef, Ref, MouseEventHandler } from "react";
import { connectStateResults } from "react-instantsearch-dom";

import { Results } from "../results";

import { Highlight } from "./highlight";

import { Link } from "ui";
import {
  APIIcon,
  AudienceIcon,
  DestinationIcon,
  EventsIcon,
  ExtensionIcon,
  HomeIcon,
  ModelIcon,
  SecurityIcon,
  SourceIcon,
  SyncIcon,
  WorkspaceIcon,
  SparkleIcon,
  IdentityResolutionIcon,
} from "ui/icons";

export type Hit = {
  objectID: string;
  title: string;
  value: string;
  path: string;
  rank: number;
  type: string;
  heirarchy: string[];
  description: string;
};

export const Hits = connectStateResults(({ searchResults }) => {
  if (searchResults?.query?.length < 1 || !searchResults?.hits) {
    return null;
  }

  if (searchResults?.hits?.length === 0) {
    return (
      <Text
        textAlign="center"
        pb={8}
        pt={4}
        color="text.primary"
        fontSize="sm"
        fontWeight="semibold"
      >
        No results match your search
      </Text>
    );
  }

  // sort to keep subsections grouped together
  const sortedResults = sortBy(searchResults.hits, ["rank", "heirarchy"]);

  return (
    <Results
      items={sortedResults}
      onRender={({ item, ...props }) => <Hit hit={item} {...props} />}
    />
  );
});

function getHitIcon(path: string) {
  const iconStyles = { fill: "text.primary", boxSize: "1.25rem" };
  if (path.includes("destinations")) return <DestinationIcon {...iconStyles} />;
  if (path.includes("sources"))
    return <SourceIcon {...iconStyles} stroke="white" />;
  if (path.includes("syncs")) return <SyncIcon {...iconStyles} />;
  if (path.includes("customer-studio")) return <AudienceIcon {...iconStyles} />;
  if (path.includes("models")) return <ModelIcon {...iconStyles} />;
  if (path.includes("security")) return <SecurityIcon {...iconStyles} />;
  if (path.includes("workspace-management"))
    return <WorkspaceIcon {...iconStyles} />;
  if (path.includes("getting-started")) return <HomeIcon {...iconStyles} />;
  if (path.includes("extensions")) return <ExtensionIcon {...iconStyles} />;
  if (path.includes("pricing")) return <ModelIcon {...iconStyles} />;
  if (path.includes("developer-tools")) return <APIIcon {...iconStyles} />;
  if (path.includes("identity-resolution"))
    return <IdentityResolutionIcon {...iconStyles} />;
  if (path.includes("events")) return <EventsIcon {...iconStyles} />;
  if (path.includes("match-booster")) return <SparkleIcon {...iconStyles} />;
  if (path.includes("campaign-intelligence"))
    return <ModelIcon {...iconStyles} />;
  return null;
}

export const Hit: VFC<
  Readonly<{
    hit: any;
    active: boolean;
    onClick: MouseEventHandler;
    onMouseMove: MouseEventHandler;
  }>
> = forwardRef(
  ({ hit, active, onClick, onMouseMove }, ref: Ref<HTMLDivElement>) => {
    const { heirarchy, path, title, type } = hit;

    return (
      <Link
        ref={ref}
        href={path}
        width="100%"
        height="100%"
        borderBottom="1px solid"
        borderColor={active ? "avocado" : "gray.100"}
        display="flex"
        alignItems="flex-start"
        p={4}
        bg={active ? "avocado" : "white"}
        lineHeight="1.625rem"
        fontWeight="semibold"
        onClick={onClick}
        onMouseMove={onMouseMove}
      >
        <Flex align="center" mt={1} mr={2} justify="center">
          {getHitIcon(path)}
        </Flex>

        <Box display="inline-block" lineHeight="1.625rem">
          {heirarchy.map((h, index) => {
            return (
              <Box key={`${h}-${index}`} display="inline-block">
                <Text as="span">{h}</Text>
                <Text as="span" fontWeight="bold" fontSize="lg">
                  &nbsp;
                  {"›"}
                  &nbsp;
                </Text>
              </Box>
            );
          })}
          {type !== "description" ? (
            <Highlight hit={hit} attribute="value" />
          ) : (
            title
          )}
        </Box>
      </Link>
    );
  }
);

Hit.displayName = "Hit";
